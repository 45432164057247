import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import Image from '../components/Image';
import { useNavigate } from 'react-router-dom';
import { useSignupContext } from '../context/SignupContext';
import { useGlobalContext } from '../hooks/useAuth';
import { useSpring, animated } from '@react-spring/web';

function App() {
	const { user, onSignUp } = useGlobalContext();
	const navigate = useNavigate();

	const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
    const loadingMessages = [
        "What's up, fren? You've arrived just in time!",
        "Hold tight! Verifying your Telegram status...",
		"Granting access to the launch pad...",
        "We're firing up the engines...",
        "Getting ready for takeoff in 3... 2... 1...",
		"Blast off! We're heading to the POCKET galaxy!",
    ];

	useEffect(() => {
        /*const messageTimer = setInterval(() => {
            setLoadingMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
        }, 2500);

        const navigateTimer = setTimeout(() => {
            navigate('/signup');
        }, 14900);

        return () => {
            clearInterval(messageTimer);
            clearTimeout(navigateTimer);
        };*/

		navigate('/signup');
    }, [navigate]);

	const fontStyle = {
        WebkitFontSmoothing: 'antialiased', // Smooth fonts on iOS
    };

	const springProps = useSpring({
        loop: { reverse: true },
        from: { y: 0 },
        to: { y: -20 },
        config: { tension: 200, friction: 10 },
    });

	return (
		<div className='flex flex-col w-screen min-h-screen'>
			<div className="flex flex-col items-center justify-center my-auto">
				<animated.div style={springProps}>
                	<Image src='./imgs/splash.svg' className='w-[400px] h-[400px]' alt='icon' />
            	</animated.div>
				<h1 className='text-center text-[35px] font-medium mt-5 bg-gradient-to-b from-white to-gray-200 bg-clip-text text-transparent' style={fontStyle}>
					POCKET
				</h1>
				<p className='text-center text-[18px] font-medium mb-6 mt-[5px] bg-gradient-to-b from-white to-gray-100 bg-clip-text text-transparent' style={fontStyle}>
					Let's put crypto in every pocket!
				</p>
				<div className="loader-container flex flex-col items-center mt-10">
					<div className="flex space-x-2 mb-4">
						{[...Array(3)].map((_, index) => (
							<animated.div
								key={index}
								className="loader-ball w-2.5 h-2.5 rounded-full"
								style={{
									background: 'linear-gradient(to bottom, white, #e5e7eb)',
									animation: `bounce 0.6s infinite alternate`,
									animationDelay: `${index * 0.2}s`,
								}}
							/>
						))}
					</div>
					<p className="text-center text-[17px] font-medium bg-gradient-to-b from-white to-gray-100 bg-clip-text text-transparent mx-5" style={fontStyle}>{loadingMessages[loadingMessageIndex]}</p>
				</div>
			</div>
		</div>
	);
}

export default App;
