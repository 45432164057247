import axios from 'axios';

// interceptor to detect if the server is unreachable
axios.interceptors.response.use(
    response => response,
    error => {
      if (error.code === 'ECONNABORTED' || error.message === 'Network Error') {
        // timeout reached, handle the unreachable server here
        const event = new CustomEvent('serverUnreachable');
        window.dispatchEvent(event);
      }
      return Promise.reject(error);
    }
);

axios.defaults.timeout = 15000; // set default timeout of 15 seconds

export default axios;