import React from 'react';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import { useTransition, animated } from '@react-spring/web';
import App from './pages/App';
import Signup from './pages/Signup';
import Home from './pages/Home';
import Tasks from './pages/Tasks';
import Friends from './pages/Friends';
import Leaderboard from './pages/Leaderboard';
import ProtectedRoute from './components/ProtectedRoute';
import { SignupProvider } from './context/SignupContext';

const ProtectedTransitionRoutes = () => {
    const location = useLocation();
    
    const transitions = useTransition(location.pathname, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 150 },
    });

    return (
        <>
            {transitions((style, item) => (
                <animated.div style={style} key={item}>
                    {item === "/home" && <Home />}
                    {item === "/tasks" && <Tasks />}
                    {item === "/friends" && <Friends />}
                    {item === "/leaderboard" && <Leaderboard />}
                </animated.div>
            ))}
        </>
    );
};

const TransitionRoutes = () => {
    const location = useLocation();
    
    const transitions = useTransition(location.pathname, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { duration: 150 },
    });

    return (
        <>
            {transitions((style, item) => (
                <animated.div style={style} key={item}>
                    {item === "/" && <App />}
                    {item === "/signup" && <Signup />}
                </animated.div>
            ))}
        </>
    );
};

const router = createBrowserRouter([
    {
        path: "/",
        element: (
            <SignupProvider>
                <TransitionRoutes />
            </SignupProvider>
        )
    },
    {
        path: "signup",
        element: (
            <SignupProvider>
                <TransitionRoutes />
            </SignupProvider>
        )
    },
    {
        element: <ProtectedRoute />,
        children: [
            {
                path: "home",
                element: <ProtectedTransitionRoutes />
            },
            {
                path: "tasks",
                element: <ProtectedTransitionRoutes />
            },
            {
                path: "friends",
                element: <ProtectedTransitionRoutes />
            },
            {
                path: "leaderboard",
                element: <ProtectedTransitionRoutes />
            }
        ]
    },
    {
        path: '*',
        element: <p>404 Error - Nothing here...</p>
    }
]);

export default router;