import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';

const ClientsContext = createContext();

// Create a single socket instance outside the component
const socket = io('/');

export const ClientsProvider = ({ children }) => {
    const [liveUsersCount, setLiveUsersCount] = useState(0);

    useEffect(() => {
        socket.on('liveUsersCount', (count) => {
            setLiveUsersCount(count);
        });

        return () => {
            socket.off('liveUsersCount');
        };
    }, []);

    return (
        <ClientsContext.Provider value={{ liveUsersCount }}>
            {children}
        </ClientsContext.Provider>
    );
};

export const useLiveUsersCount = () => useContext(ClientsContext);