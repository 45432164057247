import { useEffect, useState } from 'react';
import { useGlobalContext } from '../hooks/useAuth';
import Image from '../components/Image';
import { ReactSVG } from 'react-svg';
import { formatNumberWithLetters, formatAmericanNumber } from '../utils/numberFormatter';
import { useInView } from 'react-intersection-observer';
import { useSpring, useSprings, animated } from '@react-spring/web';
import { useSearchParams } from 'react-router-dom';
import { useLiveUsersCount } from '../context/ClientsContext';
import { useNavigate } from 'react-router-dom';
import Icons from '../assets/icons.json';

const Counter = ({ targetNumber }) => {
    const [isCountingComplete, setCountingComplete] = useState(false);

    const { number } = useSpring({
        from: { number: 0 },
        to: { number: targetNumber },
        config: { duration: 1000 },
        onRest: () => setCountingComplete(true),
    });

    const { scale } = useSpring({
        from: { scale: 1 },
        to: isCountingComplete ? 1 : 1.1,
        config: { tension: 200, friction: 10 },
        loop: isCountingComplete ? false : { reverse: true },
    });

    return (
        <animated.div
            style={{
                transform: scale.to(s => `scale(${s})`), // Apply scale animation
            }}
            className="mt-[30px] text-[30px] text-center font-medium-plus"
        >
            {number.to(n => `${formatAmericanNumber(Math.floor(n))} POCKET`)}
        </animated.div>
    );
};

const OnlineStatus = ({ liveUsersCount, fontStyle }) => {
    const props = useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
      loop: { reverse: true },
      reset: true,
    });
  
    return (
      <div className='flex items-center gap-2'>
        <animated.div 
          className="w-[10px] h-[10px] rounded-full bg-red-500" 
          style={props}
          aria-label="online" 
        />
        <p className='text-[16px] text-white text-opacity-75' style={fontStyle}>
          {formatNumberWithLetters(liveUsersCount)} online
        </p>
      </div>
    );
  };

const Home = function () {
	const {
        logoutUser,
        user ,
        fetchUser
    } = useGlobalContext();

    const { liveUsersCount } = useLiveUsersCount();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const isNewHistory = searchParams.get('newHistoryItem');

    if (isNewHistory) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.delete('newHistoryItem');
        setSearchParams(newSearchParams);
    }

    const [visibleHistory, setVisibleHistory] = useState([]); // Rewards history currently displayed
    const [loading, setLoading] = useState(true); // Loading state
    const [batchSize] = useState(20); // Number of items to load per batch

    // Intersection observer to detect when user reaches the end of the page
    const { ref, inView } = useInView({ 
        threshold: 1,
        triggerOnce: false,
    });
    
    const onLogout = async (e) => {
        await logoutUser();
    }

    const loadMoreHistory = () => {
        setLoading(true);
        const currentLength = visibleHistory.length;
        const start = user.history.length - currentLength - batchSize;
        const end = user.history.length - currentLength;

        // Get the next batch in reverse order
        const nextBatch = user.history.slice(Math.max(0, start), end).reverse();
        setVisibleHistory(prev => [...prev, ...nextBatch]);
        setLoading(false);
    };

    const springs = useSprings(
		visibleHistory.length,
		visibleHistory.map((_, index) => ({
			from: { x: (index === 0 && isNewHistory) ? -100 : 0 }, // Slide from left only for the first item
			to: { x: 0 },
			config: { tension: 75, friction: 20 },
		}))
	);

    const { scale } = useSpring({
        from: { scale: 1 },
        to: [
            { scale: 1.1, config: { tension: 80, friction: 10, duration: 1250 } }, // Upward scaling
            { scale: 1, config: { tension: 40, friction: 10, duration: 1250 } } // Downward scaling
        ],
        loop: { reverse: true },
    });

    const fontStyle = {
        WebkitFontSmoothing: 'antialiased', // Smooth fonts on iOS
    };

    // Load the first batch in reverse when component mounts or when user data is fetched
    useEffect(() => {
        fetchUser();
        if (user.history && user.history.length > 0) {
            const initialBatch = user.history.slice(-batchSize).reverse();
            setVisibleHistory(initialBatch);
            setTimeout(() => setLoading(false), 1000); // Allow initial content to load before sentinel is added
        }
    }, [user.history?.length]);

    // Load more items when user scrolls to the bottom of the page
    useEffect(() => {
        if (inView && visibleHistory.length < user.history.length) {
            loadMoreHistory();
        }
    }, [inView]);

    return (
        <div className='flex flex-col items-center w-full min-h-screen'>
            <div className='px-[25px] w-full pb-[100px]'>
                <div className='flex items-center justify-between w-full pt-[40px]'>
                    <div className='flex items-center gap-2 max-w-[70%]'>
                        <ReactSVG src={`/avatar/9.x/initials/svg?backgroundType=gradientLinear&radius=50&seed=${encodeURIComponent(user.name)}`} className='w-[41px] h-[41px]' alt='avatar' />
                        <div className='max-w-[75%]'>
                            <p className='text-[18px] font-medium w-full overflow-hidden text-ellipsis whitespace-nowrap' style={fontStyle}>{user.name}</p>
                        </div>
                    </div>
                    <OnlineStatus liveUsersCount={liveUsersCount} fontStyle={fontStyle}></OnlineStatus>
                    {/*<button className='flex items-center text-[#97c5d3] text-[14px] bg-white rounded-md px-3 py-2 my-2 font-medium' style={fontStyle}>
                        <img src={Icons.wallet2} className='w-[20px] h-[20px] mr-1' alt='wallet' /> 
                        Connect Wallet
                    </button>*/}
                </div>
                <div className='flex justify-center mt-[67px]'>
                    <div className='bg-white w-[207px] h-[207px] rounded-full flex items-center justify-center' onClick={() => navigate('/tasks')}>
                        <animated.div style={{ transform: scale.to(s => `scale(${s})`) }}>
                            <Image src="./imgs/pocketwallet.svg" className='w-[160px] h-[150px]' alt='pocket' />
                        </animated.div>
                    </div>
                </div>
                <div>
                    <Counter style={fontStyle} targetNumber={user.token} />
                    {/* <p className='flex items-center justify-center text-sm'><img src={boltImg} className='h-[17px] mr-[2px]' alt='bolt' />998/1000</p> */}
                </div>
                <div className='mt-[44px] mx-2'>
                    <div className='text-[24px] text-left font-medium mb-[10px]'>Your history</div>
                    {visibleHistory !== undefined && visibleHistory.length === 0 && (<div className='w-full py-4 mt-[24px] text-center text-[16px] bg-white bg-opacity-25 rounded-lg'><span>You haven't received any tokens yet. It's never too late to get started, fren!</span></div>) }
                    {visibleHistory !== undefined && visibleHistory.length !== 0 && visibleHistory.map((item, index) => {
                        const spring = springs[index];
                        return (
                            <animated.div
							key={index}
							style={{
								transform: spring.x.to(x => `translateX(${x}%)`)
							}}
							className='items-center justify-between py-2 w-full'
						    >
                                <div className='flex items-center gap-2'>
                                    <div className='flex items-center justify-center w-[43px] h-[43px] px-1 py-1 bg-white bg-opacity-10 rounded-full'>
                                        <Image src={Icons[item.icon]} alt={`${item.icon}`} />
                                    </div>
                                    <div className='flex-1 min-w-0'>
                                        <div className='grid grid-cols-[1fr_auto] gap-4 items-center w-full'>
                                            <div className='text-[16px] text-left font-m overflow-hidden text-ellipsis whitespace-nowrap w-full' style={fontStyle}>{item.label}</div>
                                            <div className='text-white text-[14px] text-opacity-75 text-right justify-self-end' style={fontStyle}>+ {formatAmericanNumber(item.amount)} POCKET</div>
                                        </div>
                                    </div>
                                </div>
                            </animated.div>
                        )
                    })}

                    {/* Intersection Observer sentinel */}
                    {!loading && visibleHistory !== undefined && user.history !== undefined && visibleHistory.length < user.history.length && (
                        <div ref={ref} className="loading-sentinel"></div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;