import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import axios from '../hooks/useAxios';
import { useGlobalContext } from '../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLeaderboardContext } from '../context/LeaderboardContext';
import { formatNumberWithLetters, formatAmericanNumber } from '../utils/numberFormatter';
import Icons from '../assets/icons.json';
import Image from '../components/Image';

const Leaderboard = function () {
    const { period, setPeriod, users, rank, self, count } = useLeaderboardContext();
    const { user, fetchUser } = useGlobalContext();

    const fontStyle = {
        WebkitFontSmoothing: 'antialiased', // Smooth fonts on iOS
    };

    useEffect(() => {
        fetchUser();
    }, []);
    const filteredUsers = users.length > 0 && users.find(item => item._id === self._id);
    return (
        <div className='flex flex-col pb-[100px] w-full min-h-screen'>
            <div className='flex justify-center mt-[35px]'>
                <p className='text-[30px] font-medium-plus'>The Pocket League</p>
            </div>
            <div className='w-full mt-[5px]'>
                <div className='flex mx-[30px]'>
                    <div className='flex justify-center flex-1 translate-y-20'><ReactSVG src={`/avatar/9.x/initials/svg?backgroundType=gradientLinear&radius=50&seed=${encodeURIComponent(users[1]?.name)}`} className='w-20 h-20' alt='avatar' /></div>
                    <div className='flex justify-center flex-1 translate-y-6'><ReactSVG src={`/avatar/9.x/initials/svg?backgroundType=gradientLinear&radius=50&seed=${encodeURIComponent(users[0]?.name)}`} className='w-20 h-20' alt='avatar' /></div>
                    <div className='flex justify-center flex-1 translate-y-20'><ReactSVG src={`/avatar/9.x/initials/svg?backgroundType=gradientLinear&radius=50&seed=${encodeURIComponent(users[2]?.name)}`} className='w-20 h-20' alt='avatar' /></div>
                </div>
                <div className='flex mx-[30px] items-end'>
                    <div className='flex flex-col items-center justify-center flex-1 gap-1 pt-8 pb-2 bg-[#97c5d3] rounded-l-lg max-w-[33.33%]'>
                        <div className='flex items-center justify-center w-5 h-5 text-xs -translate-y-3 bg-gradient-to-t from-[#4589c5] to-[#8dbee8] rotate-45 rounded-lg'><span className='-rotate-45'>2</span></div>
                        <div className='text-[12px] font-medium max-w-[85%] overflow-hidden text-ellipsis whitespace-nowrap' style={fontStyle}>{users[1] && users[1].name}</div>
                        <div className='text-[14px] font-medium-plus' style={fontStyle}>
                            {users[1] && (period == "week" ? formatNumberWithLetters(users[1].weeklyToken) : period == "month" ? formatNumberWithLetters(users[1].monthlyToken) : formatNumberWithLetters(users[1].token))}
                        </div>
                        <div className='flex items-center gap-1 text-[14px] text-white font-medium-plus' style={fontStyle}>
                            POCKET
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center flex-1 gap-1 pt-10 pb-10 bg-white rounded-t-3xl max-w-[33.33%]'>
                        <div className='flex items-center justify-center w-5 h-5 text-xs -translate-y-7 bg-gradient-to-t from-[#c85929] to-[#fec63c] rotate-45 rounded-lg'><span className='-rotate-45'>1</span></div>
                        <div className='text-[14px] text-[#97c5d3] font-medium max-w-[85%] overflow-hidden text-ellipsis whitespace-nowrap' style={fontStyle}>{users[0] && users[0].name}</div>
                        <div className='font-medium-plus text-[#97c5d3]' style={fontStyle}>
                            {users[0] && (period == "week" ? formatNumberWithLetters(users[0].weeklyToken) : period == "month" ? formatNumberWithLetters(users[0].monthlyToken) : formatNumberWithLetters(users[0].token))}
                        </div>
                        <div className='flex items-center gap-1 text-[14px] text-[#97c5d3] font-medium-plus' style={fontStyle}>
                            POCKET
                        </div>
                    </div>
                    <div className='flex flex-col items-center justify-center flex-1 gap-1 pt-8 pb-2 bg-[#97c5d3] rounded-r-lg max-w-[33.33%]'>
                        <div className='flex items-center justify-center w-5 h-5 text-xs -translate-y-3 bg-gradient-to-t from-[#989898] to-[#d4d4d4] rotate-45 rounded-lg'><span className='-rotate-45'>3</span></div>
                        <div className='text-[12px] font-medium max-w-[85%] overflow-hidden text-ellipsis whitespace-nowrap' style={fontStyle}>{users[2] && users[2].name}</div>
                        <div className='text-[14px] font-medium-plus' style={fontStyle}>
                            {users[2] && (period == "week" ? formatNumberWithLetters(users[2].weeklyToken) : period == "month" ? formatNumberWithLetters(users[2].monthlyToken) : formatNumberWithLetters(users[2].token))}
                        </div>
                        <div className='flex items-center gap-1 text-[14px] text-white font-medium-plus' style={fontStyle}>
                            POCKET
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-center gap-4 mt-5'>
                <Link to='/home' className='flex-col items-center justify-center px-4 py-3 bg-[#97c5d3] rounded-xl'>
                    <div className='font-medium-plus text-center' style={fontStyle}>{period == "week" ? formatNumberWithLetters(user.weeklyToken) : period == "month" ? formatNumberWithLetters(user.monthlyToken) : formatNumberWithLetters(user.token)}</div>
                    <div className='flex items-center gap-1 text-[12px] mt-[3px]' style={fontStyle}>Your Tokens <Image src={Icons.arrowRight} /></div>
                </Link>
                <Link to='/leaderboard' className='flex-col items-center justify-center px-4 py-3 bg-[#97c5d3] rounded-xl'>
                    <div className='font-medium-plus text-center' style={fontStyle}>#{formatNumberWithLetters(rank)}</div>
                    <div className='flex items-center gap-1 text-[12px] mt-[3px]' style={fontStyle}>Your Rank <Image src={Icons.arrowRight} /></div>
                </Link>
            </div>
            <div className='flex mx-[30px] mt-4'>
                <div className='text-[21px] text-white font-medium-plus' style={fontStyle}>{formatAmericanNumber(count)} holders</div>
            </div>
            <div className='flex justify-between items-center py-[4px] mx-[30px] mt-4 mb-[30px] bg-white bg-opacity-25 rounded-xl'>
                <div onClick={() => setPeriod('week')} className={`flex-1 text-center text-[14px] transition-all duration-300 font-medium-plus cursor-pointer py-[10px] rounded-lg ml-1 ${ period == 'week' ? '' : 'hover:' }bg-[#97c5d3]`} style={fontStyle}>Weekly</div>
                <div onClick={() => setPeriod('month')} className={`flex-1 text-center text-[14px] transition-all duration-300 font-medium-plus cursor-pointer py-[10px] rounded-lg mx-1 ${ period == 'month' ? '' : 'hover:' }bg-[#97c5d3]`} style={fontStyle}>Monthly</div>
                <div onClick={() => setPeriod('all')} className={`flex-1 text-center text-[14px] transition-all duration-300 font-medium-plus cursor-pointer py-[10px] rounded-lg mr-1 ${ period == 'all' ? '' : 'hover:' }bg-[#97c5d3]`} style={fontStyle}>All time</div>
            </div>
            <div>
                {users.length > 0 && users.map((item, index)=>{
                    return (
                        <div className={item._id == self._id ? 'flex items-center mx-[30px] justify-between px-3 py-2 bg-white bg-opacity-70 rounded-lg' :'flex items-center mx-[30px] justify-between px-3 py-2 border-b border-white border-opacity-20'}>
                            <div className='flex gap-2 w-[90%]'>
                                <ReactSVG src={`/avatar/9.x/initials/svg?backgroundType=gradientLinear&radius=50&seed=${encodeURIComponent(item.name)}`} alt='avatar image' className='w-[41px] h-[41px]' />
                                <div className='max-w-[75%]'>
                                    <div className={item._id == self._id ? 'text-[16px] items-center text-[#6595a8] w-full overflow-hidden text-ellipsis whitespace-nowrap': 'text-[16px] items-center w-full overflow-hidden text-ellipsis whitespace-nowrap'} style={fontStyle}>{item.name}
                                     {item._id == self._id ? <span className='ml-2 bg-[#6595a8] text-white rounded-full text-[12px] px-3 font-medium-plus' style={fontStyle}>You</span> : ''}
                                     </div>
                                    <div className={item._id == self._id ? 'text-[#6595a8] text-[14px]' : 'text-white text-[14px] text-opacity-75'} style={fontStyle}>
                                        {period == "week" ? formatAmericanNumber(item.weeklyToken) : period == "month" ? formatAmericanNumber(item.monthlyToken) : formatAmericanNumber(item.token)} POCKET
                                    </div>
                                </div>
                            </div>
                            <div className={item._id == self._id ? 'font-medium-plus text-[14px] text-[#6595a8]' : 'font-medium-plus text-[14px]'} style={fontStyle}>#{formatAmericanNumber(index + 1)}</div>
                        </div>
                    )
                })}
                {filteredUsers == null && (
                    <div className='fixed bottom-[100px] inset-x-0 flex items-center mx-[30px] justify-between px-3 py-2 bg-[#E4EFF3] bg-opacity-100 rounded-lg transition duration-300 transform active:opacity-0'>
                    <div className='flex gap-2 w-[90%]'>
                        <ReactSVG src={`/avatar/9.x/initials/svg?backgroundType=gradientLinear&radius=50&seed=${encodeURIComponent(self.name)}`} alt='avatar image' className='w-[41px] h-[41px]' />
                        <div className='max-w-[75%]'>
                            <div className='text-[16px] items-center text-[#6595a8] w-full overflow-hidden text-ellipsis whitespace-nowrap' style={fontStyle}>{self.name}
                             <span className='ml-2 bg-[#6595a8] text-white rounded-full text-[12px] px-3 font-medium-plus' style={fontStyle}>You</span>
                             </div>
                            <div className='text-[#6595a8] text-[14px]' style={fontStyle}>
                                {period == "week" ? formatAmericanNumber(self.weeklyToken) : period == "month" ? formatAmericanNumber(self.monthlyToken) : formatAmericanNumber(self.token)} POCKET
                            </div>
                        </div>
                    </div>
                    <div className='font-medium-plus text-[14px] text-[#6595a8]' style={fontStyle}>#{formatAmericanNumber(rank)}</div>
                </div>
                )}
            </div>
        </div>
    );
}

export default Leaderboard;