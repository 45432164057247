import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import router from './router';
import { RouterProvider, useLocation } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './hooks/useAuth';
import { ClientsProvider } from './context/ClientsContext';
import { initMiniApp } from '@telegram-apps/sdk';
import WebApp from '@twa-dev/sdk';
import LoaderVideo from './assets/loader.json';
import { TwaAnalyticsProvider } from '@tonsolutions/telemetree-react';

const [miniApp] = initMiniApp();
miniApp.setHeaderColor('#b3ced8');
WebApp.expand();

const Loader = () => {
  return (
    <div id="loader">
      <img src={LoaderVideo.loaderAnimation} alt="loading" style={{ width: '350px', height: 'auto' }}></img>
    </div>
  );
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [serverReachable, setServerReachable] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleServerUnreachable = () => setServerReachable(false);
    window.addEventListener('serverUnreachable', handleServerUnreachable);

    const handleOffline = () => setIsOnline(false);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('serverUnreachable', handleServerUnreachable);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4500); // Wait for 4.5 seconds

    return () => clearTimeout(timer);
  }, []);

  // show loader if the app is still loading, if internet offline or if the server is unreachable
  if (loading || !isOnline || !serverReachable) {
    return <Loader />;
  }

  return (
    <TwaAnalyticsProvider
      projectId='3414fdbd-0c2a-4958-8aff-1b69e86eb4fd'
      apiKey='6fccd413-bdc9-4ef2-a5f6-07c4260acd1c'
      appName='POCKET'
    >
      <RouterProvider router={router} />
    </TwaAnalyticsProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AppProvider>
        <ClientsProvider>
          <App />
        </ClientsProvider>
      </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
