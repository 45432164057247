import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../hooks/useAxios';
import { useGlobalContext } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const SignupContext = createContext();

export const SignupProvider = ({ children }) => {
    const navigate = useNavigate();

    const { onSignUp, user } = useGlobalContext();
    const [bonus, setWelcomeBonus] = useState({});
    const [tokenAmount, setTokenAmount] = useState(0);
    const [isFirstBonus, setFirstBonus] = useState(false);

    useEffect(() => {
		if(user?.username) navigate('/home');
		getWelcomeBonus();
        onSignUp('splash');
	}, [user]);

    const getWelcomeBonus = async () => {
        const res = await axios.get(`/api/v1/tasks/signup`, {});
        setWelcomeBonus(res.data.task);

		if (res.data.task.task_metadata?.first_bonus && res.data.task.task_metadata.first_bonus.current_claims < res.data.task.task_metadata.first_bonus.max_claims) {
			setTokenAmount(res.data.task.task_metadata.first_bonus.bonus_amount);
            setFirstBonus(true);
		} else {
			setTokenAmount(res.data.task.token_amount);
		}

        console.log('signup=', res.data.task);
    }

    return (
        <SignupContext.Provider value={{ bonus, tokenAmount, isFirstBonus, onSignUp }}>
            {children}
        </SignupContext.Provider>
    );
};

export const useSignupContext = () => useContext(SignupContext);