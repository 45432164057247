import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';
import { useGlobalContext } from '../hooks/useAuth';
import { LeaderboardProvider } from '../context/LeaderboardContext';
import { FriendsProvider } from '../context/FriendsContext';
import { TasksProvider } from '../context/TasksContext';
import Footer from './Footer';

const ProtectedRoute = () => {
    const { user } = useGlobalContext();
    console.log("user=", user);
    if (!user) {
      return <Navigate to="/" replace />;
    }
  
    return (
      <>
        <LeaderboardProvider>
          <TasksProvider>
            <FriendsProvider>
              <Outlet />
            </FriendsProvider>
          </TasksProvider>
        </LeaderboardProvider>
        <Footer />
      </>
    ) 
  };

export default ProtectedRoute;
