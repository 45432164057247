import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../hooks/useAxios';
import { initUtils } from '@telegram-apps/sdk';
import { useGlobalContext } from '../hooks/useAuth';

const utils = initUtils();
const FriendsContext = createContext();

export const FriendsProvider = ({ children }) => {
    const [friends, setFriends] = useState([]);
    const [totalFriends, setTotalFriends] = useState(0);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [invite, setInvite] = useState({});
    const [invitePremium, setInvitePremium] = useState({});
    const { user } = useGlobalContext();
    const limit = 20; // number of friends to fetch per page

    const getFriends = async (pageNumber = page) => {
        if (loading || !hasMore) return; // prevent duplicate or excessive requests
        setLoading(true);

        try {
            const res = await axios.get(`/api/v1/users/friends?page=${pageNumber}&limit=${limit}`);
            const newFriends = res.data.friends;

            // append new friends to the list
            setFriends(prevFriends => {
                // ensure no duplicates by filtering out already fetched friends
                const existingIds = new Set(prevFriends.map(friend => friend._id));
                const uniqueFriends = newFriends.filter(friend => !existingIds.has(friend._id));
                return [...prevFriends, ...uniqueFriends];
            });

            // set total friends count
            setTotalFriends(res.data.totalFriends);

            setHasMore(newFriends.length > 0); // stop loading if no more friends are fetched
            setPage(pageNumber + 1); // increment page to load next batch

            console.log('friends=', res.data.friends);
        } catch (error) {
            console.error('Failed to fetch friends:', error);
        } finally {
            setLoading(false);
        }
    };

    const refreshFriends = () => {
        // reset to initial state and fetch friends from scratch
        setFriends([]);
        setPage(1);
        setHasMore(true);
        getFriends(1);
    };

    const getInviteTask = async () => {
        try {
            const res = await axios.get(`/api/v1/tasks/invite`);
            setInvite(res.data.task);
            console.log('invite=', res.data.task);
        } catch (error) {
            console.error('Failed to fetch invite task:', error);
        }
    };

    const getInvitePremiumTask = async () => {
        try {
            const res = await axios.get(`/api/v1/tasks/invite/premium`);
            setInvitePremium(res.data.task);
            console.log('invite premium=', res.data.task);
        } catch (error) {
            console.error('Failed to fetch invite premium task:', error);
        }
    };

    const handleInviteFriend = async function () {
        if (!user) return console.error('Can\'t get telegram user info');
        const inviteLink = `https://t.me/PocketClubBot/POCKET?startapp=${user.referralCode}`;
        const shareText = "Hey, fren! 🤙 Join my Pocket squad and start stacking up POCKET tokens right away! Just hop on with my link, and you'll receive some POCKET to kickstart your journey! Don't miss out - can't wait for you to join me! 😉";
        const fullUrl = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent(shareText)}`;
        utils.openTelegramLink(fullUrl);
    }

    const handleCopyInviteLink = function () {
        if (!user) return console.error('Can\'t get telegram user info');
        const inviteLink = `https://t.me/PocketClubBot/POCKET?startapp=${user.referralCode}`;
        navigator.clipboard.writeText(inviteLink).then(() => {
            const copyTextElement = document.getElementById('copytext');
            copyTextElement.innerText = 'Copied';
            setTimeout(() => {
                copyTextElement.innerText = 'Copy';
            }, 1000);
        });
    }

    useEffect(() => {
        getInviteTask();
        getInvitePremiumTask();
    }, []);

    // only fetch friends initially if the list is empty
    useEffect(() => {
        if (friends.length === 0 && hasMore) {
            getFriends();
        }
    }, []);

    return (
        <FriendsContext.Provider value={{
            friends,
            totalFriends,
            hasMore,
            loading,
            invite,
            invitePremium,
            handleInviteFriend,
            handleCopyInviteLink,
            getFriends,
            refreshFriends
        }}>
            {children}
        </FriendsContext.Provider>
    );
};

export const useFriendsContext = () => useContext(FriendsContext);