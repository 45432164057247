export const formatAmericanNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

export const formatNumberWithLetters = (number) => {
    if (number < 100000) {
        return formatAmericanNumber(number);
    } else if (number < 1000000) {
        return (number / 1000).toFixed(2) + 'K'; // Rounds to two decimal places
    } else {
        return (number / 1000000).toFixed(2) + 'M';
    }
};