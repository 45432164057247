import { ReactSVG } from 'react-svg';
import WebApp from '@twa-dev/sdk';
import axios from '../hooks/useAxios';
import React, { useEffect, useState, useCallback } from 'react';
import { useGlobalContext } from '../hooks/useAuth';
import Image from '../components/Image';
import { useFriendsContext } from '../context/FriendsContext';
import { formatNumberWithLetters, formatAmericanNumber } from '../utils/numberFormatter';
import { useInView } from 'react-intersection-observer';
import Icons from '../assets/icons.json';

const Friends = function () {
    const { friends, invite, invitePremium, handleInviteFriend, handleCopyInviteLink, getFriends, refreshFriends, totalFriends, hasMore, loading } = useFriendsContext();
    const { user } = useGlobalContext();

    // Intersection observer to detect when user reaches the end of the page
    const { ref, inView } = useInView({ 
        threshold: 1,
        triggerOnce: false,
    });

    // Fetch more friends when user reaches the bottom and we have more to load
    const loadMoreFriends = useCallback(() => {
        if (inView && hasMore && !loading) {
            getFriends();
        }
    }, [inView, hasMore, loading, getFriends]);

    // Load initial friends batch on component mount
    useEffect(() => {
        if (friends.length === 0) {
            getFriends();
        }
    }, [getFriends, friends.length]);

    useEffect(() => {
        loadMoreFriends();
    }, [inView]);

    const fontStyle = {
        WebkitFontSmoothing: 'antialiased', // Smooth fonts on iOS
    };

    return (
        <div className='flex flex-col pb-[100px] w-full min-h-screen'>
            <div className='w-full mt-[5px]'>
                <div className='relative z-10 flex justify-center'>
                    <Image src="./imgs/invitefriends.svg" className='w-[190px] h-[170px]' alt='invite' />
                </div>
                <div className='pt-[35px] pb-[21px] mx-[61px] text-center -translate-y-[52px] bg-white bg-opacity-25 rounded-lg'>
                    <div className='text-[28px] font-medium-plus' style={fontStyle}>Invite friends</div>
                    <div className='text-[14px] font-medium mt-[4px]' style={fontStyle}>You and your friend will receive tokens</div>
                </div>
            </div>
            <div className='w-full mt-[14px]'>
                <div className='mx-[30px] -mt-[44px] text-left font-medium text-lg' style={fontStyle}>Earned rewards</div>
                <div className='mx-[30px] mt-[8px] py-2 grid justify-items-center grid-cols-2 bg-white bg-opacity-25 rounded-lg'>
                    <div className='col-span-2 flex items-center justify-center'>
                        {/*<img src='./imgs/logo.svg' className='mr-[5px] w-[30px] h-[30px]' />*/}
                        <span className='font-medium-plus text-[19px]' style={fontStyle}>{formatAmericanNumber(user.referralToken)} POCKET</span>
                    </div>
                </div>
            </div>
            <div className='w-full mt-4 px-[30px]'>
                <div className='flex justify-between gap-[13px]'>
                    <button onClick={handleInviteFriend} className='flex-[8] py-3 font-medium text-[#97c5d3] text-[16px] bg-white rounded-lg shadow-md transition duration-150 active:shadow-inner active:bg-gray-200'>Invite a friend</button>
                    <button onClick={handleCopyInviteLink} className='flex-[4] flex items-center font-medium justify-center gap-3 px-5 py-3 text-[16px] bg-white bg-opacity-25 rounded-lg shadow-md transition duration-150 active:shadow-inner active:bg-gray-300'><Image src={Icons.link} /><span id='copytext'>Copy</span></button>
                </div>
            </div>
            <div className='w-full'>
                <div className='flex items-center gap-3 px-3 py-2 mx-[30px] mt-[29px] border rounded-lg'>
                    <Image src={`./imgs/${invite.task_icon}.svg`} className='w-[75px] h-[75px]' alt={`${invite.task_icon}`} />
                    <div>
                        <div className='text-[20px] font-medium-plus' style={fontStyle}>{invite.name}</div>
                        <div className='text-[#eef5f8] text-[18px]' style={fontStyle}>+{formatAmericanNumber(invite.token_amount)} POCKET</div>
                    </div>
                </div>
            </div>
            <div className='w-full'>
                <div className='flex items-center gap-3 px-3 py-2 mx-[30px] mt-[29px] border rounded-lg'>
                    <Image src={`./imgs/${invitePremium.task_icon}.svg`} className='w-[75px] h-[75px]' alt={`${invitePremium.task_icon}`} />
                    <div>
                        <div className='text-[20px] leading-[21px] font-medium-plus' style={fontStyle}>{invitePremium.name}</div>
                        <div className='text-[#eef5f8] text-[18px] mt-[11px]' style={fontStyle}>+{formatAmericanNumber(invitePremium.token_amount)} POCKET</div>
                    </div>
                </div>
            </div>
            <div className='flex justify-between items-center mt-[20px] mx-[33px]'>
                <h1 className='text-lg font-medium' style={fontStyle}>Friends team</h1>
                <div className='text-lg font-regular' style={fontStyle}>{totalFriends} invited</div>
            </div>
            <div className='mx-[33px] mt-[12px] py-4 text-center text-[16px] bg-white bg-opacity-25 rounded-lg'>
                {friends !== undefined && friends.length === 0 && !loading && (
                <div className='px-4'>
                    <Image src='./imgs/referral.svg' className='w-[150px] h-[150px] mx-auto' alt="empty invites" />
                    <span className='text-gray-100'>Hey mate, this looks a little empty. Maybe it’s time to bring in your squad!</span>
                </div>
                ) }
                {friends !== undefined && friends.length !== 0 && friends.map((item) => {
                    return (
                        <div key={item._id} className='flex items-center mx-[25px] justify-between px-3 py-2 border-b border-white border-opacity-20'>
                            <div className='flex gap-2 w-[90%]'>
                                <ReactSVG src={`/avatar/9.x/initials/svg?backgroundType=gradientLinear&radius=50&seed=${encodeURIComponent(item.name)}`} alt='avatar' className='w-[41px] h-[41px]' />
                                <div className='max-w-[75%]'>
                                    <div className='text-[16px] items-center font-m w-full overflow-hidden text-ellipsis whitespace-nowrap' style={fontStyle}>{item.name}</div>
                                    <div className='text-white text-[14px] text-opacity-75' style={fontStyle}>{formatAmericanNumber(item.token)} POCKET</div>
                                </div>
                            </div>
                            
                        </div>
                    )
                })}
                
                {/* Intersection Observer sentinel */}
                <div ref={ref} className="loading-sentinel"></div>
            </div>
        </div>
    );
}

export default Friends;