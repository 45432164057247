import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from '../hooks/useAxios';

const LeaderboardContext = createContext();

export const LeaderboardProvider = ({ children }) => {
    const [period, setPeriod] = useState('week');
    const [data, setData] = useState({
        week: { users: [], rank: 0, self: {}, count: 0 },
        month: { users: [], rank: 0, self: {}, count: 0 },
        all: { users: [], rank: 0, self: {}, count: 0 },
    });
    const [loading, setLoading] = useState(false);

    const getLeaderboard = async (type) => {
        setLoading(true);  // Start loading
        try {
            const res = await axios.post(`/api/v1/users/leaderboard`, { type });
            setData(prevData => ({
                ...prevData,
                [type]: {
                    users: res.data.users,
                    rank: res.data.rank,
                    self: res.data.self,
                    count: res.data.count,
                },
            }));
        } catch (error) {
            console.error("Error fetching leaderboard data:", error);
        } finally {
            setLoading(false); // End loading
        }
    };

    useEffect(() => {
        // Load weekly leaderboard data on initial mount
        getLeaderboard('week');
    }, []);

    useEffect(() => {
        // Load other leaderboard periods data when period changes including on the initial load
        const otherPeriods = Object.keys(data).filter(p => p !== period);
        otherPeriods.forEach(p => getLeaderboard(p));
    }, [period]);

    return (
        <LeaderboardContext.Provider value={{
            period,
            setPeriod,
            users: data[period].users,
            rank: data[period].rank,
            self: data[period].self,
            count: data[period].count,
            loading,
        }}>
            {children}
        </LeaderboardContext.Provider>
    );
};

export const useLeaderboardContext = () => useContext(LeaderboardContext);