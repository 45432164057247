import { useState } from "react"

const Image = ({ className, ...props }) => {
    const [show, setShow] = useState(false);

    return (
        <>
            { !show && <div className={className} /> }
            <img className={className} { ...props } style={{ display: show ? 'block' : 'none' }} onLoad={() => setShow(true)} />
        </>
    )
}

export default Image;