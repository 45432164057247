import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../hooks/useAxios';
import { initUtils, initPopup } from '@telegram-apps/sdk';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../hooks/useAuth';

const utils = initUtils();
const popup = initPopup();
const TasksContext = createContext();

export const TasksProvider = ({ children }) => {
    const [tasks, setTasks] = useState([]);
    const [dailyTasks, setDailyTasks] = useState([]);
    const [dailyRemainSecond, setDailyRemainSecond] = useState(0);
    const [taskStatuses, setTaskStatuses] = useState({});
    const [loading, setLoading] = useState(true);
    const [claimStates, setClaimStates] = useState({});
    const { user } = useGlobalContext();

    const navigate = useNavigate();

    const getTasks = async () => {
        try {
            const res = await axios.get(`/api/v1/tasks`);
            const filteredTasks = res.data.tasks?.filter(task => {
                // check if the task id exists in user history
                const taskCompleted = user.history.some(historyItem => historyItem.taskId && historyItem.taskId.toString() === task._id.toString());
                // keep the task if it is persistent or not in user history
                return task?.is_persistent_task === true || !taskCompleted;
            }) || [];
            setTasks(filteredTasks);
            console.log('Fetched tasks:', filteredTasks);
        } catch (error) {
            console.error('Failed to fetch tasks:', error);
        }
    };

    const getDailyTasks = async () => {
        try {
            const res = await axios.get(`/api/v1/tasks/daily`);
            const filteredDailyTasks = res.data.tasks?.filter(task => {
                // check if the task id exists in user history
                const taskCompleted = user.history.some(historyItem => historyItem.taskId && historyItem.taskId.toString() === task._id.toString());
                // keep the task if it is persistent or not in user history
                return task?.is_persistent_task === true || !taskCompleted;
            }) || [];
            setDailyTasks(filteredDailyTasks);
            console.log('Fetched daily tasks:', filteredDailyTasks);
        } catch (error) {
            console.error('Failed to fetch daily tasks:', error);
        }
    };

    const handleTelegram = async (tgLink, taskId) => {
        if (taskStatuses[taskId] === false) return;
        if (claimStates[taskId]) {
            const res = await axios.post(`/api/v1/users/jointg`, { status: 1, task_id: taskId });
            if (res.data.success) {
                const task = tasks.find(t => t._id === taskId);
                if (task?.is_persistent_task !== true) {
                    setTasks(prev => prev.filter(t => t._id !== taskId));
                }
                updateTaskStatus(taskId, false);
                navigate(`/home?newHistoryItem=true`);
            } else if (res.data.status === 'notjoined') {
                popup.open({
                    title: 'Join the channel',
                    message: 'Join the Telegram channel to claim the reward.',
                    buttons: [{ id: 'dismiss', type: 'default', text: 'OK' }],
                }).then(buttonId => { console.log(buttonId); });
            } else if (res.data.status === 'exist') {
                // task already done
                popup.open({
                    title: 'Task completed',
                    message: 'You already joined the Telegram channel.',
                    buttons: [{ id: 'dismiss', type: 'default', text: 'OK' }],
                }).then(buttonId => { console.log(buttonId); });
            }
            setClaimStates((prev) => ({ ...prev, [taskId]: false }));
        } else {
            utils.openTelegramLink(tgLink);
            setClaimStates((prev) => ({ ...prev, [taskId]: true }));
        }
    };

    const handleInviteFriends = async (taskId) => {
        if (taskStatuses[taskId] === false) return;
        if (claimStates[taskId]) {
            const res = await axios.post(`/api/v1/users/invitefriends`, { status: 1, task_id: taskId });
            if (res.data.success) {
                const task = tasks.find(t => t._id === taskId);
                if (task?.is_persistent_task !== true) {
                    setTasks(prev => prev.filter(t => t._id !== taskId));
                }
                updateTaskStatus(taskId, false);
                navigate(`/home?newHistoryItem=true`);
            } else if (res.data.status === 'notinvited') {
                navigate(`/friends`);
            } else if (res.data.status === 'exist') {
                // task already done
                popup.open({
                    title: 'Task completed',
                    message: 'You already invited the amount of friends required.',
                    buttons: [{ id: 'dismiss', type: 'default', text: 'OK' }],
                }).then(buttonId => { console.log(buttonId); });
            }
            setClaimStates((prev) => ({ ...prev, [taskId]: false }));
        } else {
            setClaimStates((prev) => ({ ...prev, [taskId]: true }));
        }
    };

    const handleTelegramPremium = async (taskId) => {
        if (taskStatuses[taskId] === false) return;
        if (claimStates[taskId]) {
            const res = await axios.post(`/api/v1/users/tgpremium`, { status: 1, task_id: taskId });
            if (res.data.success) {
                const task = tasks.find(t => t._id === taskId);
                if (task?.is_persistent_task !== true) {
                    setTasks(prev => prev.filter(t => t._id !== taskId));
                }
                updateTaskStatus(taskId, false);
                navigate(`/home?newHistoryItem=true`);
            } else if (res.data.status === 'notpremium') {
                popup.open({
                    title: 'Telegram Premium',
                    message: 'Subscribe to Telegram Premium to claim the reward.',
                    buttons: [{ id: 'dismiss', type: 'default', text: 'OK' }],
                }).then(buttonId => { console.log(buttonId); });
            } else if (res.data.status === 'exist') {
                // task already done
                popup.open({
                    title: 'Task completed',
                    message: 'You already subscribed to Telegram Premium.',
                    buttons: [{ id: 'dismiss', type: 'default', text: 'OK' }],
                }).then(buttonId => { console.log(buttonId); });
            }
            setClaimStates((prev) => ({ ...prev, [taskId]: false }));
        } else {
            setClaimStates((prev) => ({ ...prev, [taskId]: true }));
        }
    };

    const handleTwitter = async (twitterLink, taskId) => {
        if (taskStatuses[taskId] === false) return;
        if (claimStates[taskId]) {
            const res = await axios.post(`/api/v1/users/followx`, { status: 1, task_id: taskId });
            if (res.data.success) {
                const task = tasks.find(t => t._id === taskId);
                if (task?.is_persistent_task !== true) {
                    setTasks(prev => prev.filter(t => t._id !== taskId));
                }
                updateTaskStatus(taskId, false);
                navigate(`/home?newHistoryItem=true`);
            } else if (res.data.status === 'exist') {
                // task already done
                popup.open({
                    title: 'Task completed',
                    message: 'You already followed the X account.',
                    buttons: [{ id: 'dismiss', type: 'default', text: 'OK' }],
                }).then(buttonId => { console.log(buttonId); });
            }
            setClaimStates((prev) => ({ ...prev, [taskId]: false }));
        } else {
            utils.openLink(twitterLink);
            setClaimStates((prev) => ({ ...prev, [taskId]: true }));
        }
    };

    const onClaimDailyReward = async (taskId) => {
        if (taskStatuses[taskId] === false) return;
        const res = await axios.post(`/api/v1/users/claim_daily`, { status: 1, task_id: taskId });
        if (res.data.success) {
            const task = dailyTasks.find(t => t._id === taskId);
            if (task?.is_persistent_task !== true) {
                setDailyTasks(prev => prev.filter(t => t._id !== taskId));
            }
            setDailyRemainSecond(res.data.ms);
            updateTaskStatus(taskId, false);
            navigate(`/home?newHistoryItem=true`);
        } else {
            // daily bonus already claimed
            popup.open({
                title: 'Daily reward claimed',
                message: 'You already received your daily allocation.',
                buttons: [{ id: 'dismiss', type: 'default', text: 'OK' }],
            }).then(buttonId => { console.log(buttonId); });
        }
    };

    const getClaimStatus = async (taskId) => {
        const res = await axios.post(`/api/v1/users/claim_daily`, { task_id: taskId});
        if(res.data.success === false) {
            setDailyRemainSecond(res.data.ms);
        }
        console.log('getClaimStatus=', res);
        return (res.data.ms <= 0 ? true : false);
    };

    const getTGStatus = async (taskId) => {
        const res = await axios.post(`/api/v1/users/jointg`, { task_id: taskId});
        if(res.data.success === true && res.data.status === 0) {
            return (res.data.jointg === 1 ? false : true);
        }
        console.log('getTGStatus=', res);
    };

    const getInviteStatus = async (taskId) => {
        const res = await axios.post(`/api/v1/users/invitefriends`, { task_id: taskId});
        if(res.data.success === true && res.data.status === 0) {
            return (res.data.invite === 1 ? false : true);
        }
        console.log('getInviteStatus=', res);
    };

    const getTGPremiumStatus = async (taskId) => {
        const res = await axios.post(`/api/v1/users/tgpremium`, { task_id: taskId});
        if(res.data.success === true && res.data.status === 0) {
            return (res.data.premium === 1 ? false : true);
        }
        console.log('getTGPremiumStatus=', res);
    };

    const getXStatus = async (taskId) => {
        const res = await axios.post(`/api/v1/users/followx`, { task_id: taskId});
        if(res.data.success === true && res.data.status === 0) {
            return (res.data.followx === 1 ? false : true);
        }
        console.log('getXStatus=', res);
    };

    const updateTaskStatus = (taskId, status) => {
        setTaskStatuses((prevStatuses) => ({
            ...prevStatuses,
            [taskId]: status,
        }));
    };

    useEffect(() => {
        if (user.history && user.history.length > 0) {
            getTasks();
            getDailyTasks();
        }
    }, [user.history?.length]);

    useEffect(() => {
        async function fetchAllStatuses() {
            const statuses = {};
            
            // Combine tasks and daily tasks into one array
            const allTasks = [...tasks, ...dailyTasks];
    
            await Promise.all(allTasks.map(async (task) => {
                if (task.task_type === 'tg-join') {
                    statuses[task._id] = await getTGStatus(task._id);
                } else if (task.task_type === 'tw-follow') {
                    statuses[task._id] = await getXStatus(task._id);
                } else if (task.task_type === 'daily-bonus') {
                    statuses[task._id] = await getClaimStatus(task._id);
                } else if (task.task_type === 'invite-friends') {
                    statuses[task._id] = await getInviteStatus(task._id);
                } else if (task.task_type === 'tg-premium') {
                    statuses[task._id] = await getTGPremiumStatus(task._id);
                }
            }));

            setTaskStatuses(prevStatuses => ({ ...prevStatuses, ...statuses }));
        }
    
        if (tasks.length > 0 || dailyTasks.length > 0) {
            setLoading(true);
            try {
                fetchAllStatuses();
            }
            catch (error) {
                console.error('Failed to fetch task statuses:', error);
            }
            finally {
                setLoading(false);
            }
        }
    }, [tasks, dailyTasks, dailyRemainSecond<=0]);

    return (
        <TasksContext.Provider
            value={{
                tasks,
                dailyTasks,
                dailyRemainSecond,
                taskStatuses,
                loading,
                claimStates,
                handleTelegram,
                handleTwitter,
                handleInviteFriends,
                handleTelegramPremium,
                onClaimDailyReward,
                refreshClaimStatus: getClaimStatus,
                getTGStatus,
                getInviteStatus,
                getTGPremiumStatus,
                getXStatus,
                setDailyRemainSecond
            }}
        >
            {children}
        </TasksContext.Provider>
    );
};

export const useTasksContext = () => useContext(TasksContext);