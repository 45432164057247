import React, { useState, useEffect } from 'react';
import { useGlobalContext } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Image from '../components/Image';
import axios from '../hooks/useAxios';
import { ReactSVG } from 'react-svg';
import { useSignupContext } from '../context/SignupContext';
import { formatNumberWithLetters, formatAmericanNumber } from '../utils/numberFormatter';

const Signup = function () {
	const navigate = useNavigate();
	const { bonus, tokenAmount, isFirstBonus, onSignUp } = useSignupContext();
	
	const fontStyle = {
        WebkitFontSmoothing: 'antialiased', // Smooth fonts on iOS
    };

    return (
		<div className='flex flex-col items-center w-screen min-h-screen'>
			<div className="flex flex-col items-center w-full my-auto">
				<p className='font-medium-plus mt-5 text-[20px]' style={fontStyle}>{bonus.name}</p>
				<p className='text-3xl font-medium-plus mt-[5px]' style={fontStyle}>+{formatAmericanNumber(tokenAmount)} POCKET</p>
				<div className='mt-[23px]'>
					<Image className='w-[400px] h-[400px]' src={`./imgs/${bonus.task_icon}.svg`} alt={`${bonus.task_icon}`} />
				</div>
				<p className='mt-[23px] text-[17px] mx-6 text-center font-medium' style={fontStyle}>Hey buddy! It's great to see you around! Here's some POCKET to kick things off. Jump on board and let's start exploring!</p>
				{ isFirstBonus && (<p className='mx-6 mt-3 text-[15px] text-center font-medium text-white text-opacity-75' style={fontStyle}>You're our #{bonus.task_metadata.first_bonus.current_claims+1} fren to claim the boosted {formatAmericanNumber(tokenAmount)} POCKET reward. Lucky you!</p>)}
				<button onClick={() => onSignUp()} className='w-[315px] h-[46px] mx-auto font-medium-plus text-[18px] flex justify-center mt-[25px] mb-5 items-center text-[#97c5d3] bg-gradient-to-b from-white to-gray-100 rounded-lg shadow-md transition duration-150 active:shadow-inner active:bg-gradient-to-t' style={fontStyle}>Let's Get Started!</button>
			</div>
		</div>
    );
}

export default Signup;