import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import {Link} from 'react-router-dom';
import Icons from '../assets/icons.json';
import Image from './Image';

const Footer = function () {
    const location = useLocation();

    return (
        <div className='fixed bottom-0 left-0 flex justify-between items-center w-screen h-[87px] bg-[#8ebbc9] rounded-t-[34px]'>
            <Link to='/home' className={`flex flex-col items-center w-32 transition-all duration-300 group ${location.pathname === '/home' ? 'scale-125' : 'hover:scale-125'}`}>
                {/* <img src={homeImg} className='h-[20px]' alt='home image' /> */}
                <Image src={Icons.home} />
                <span className='text-[10px]'>Home</span>
                <img src={Icons.triangle} className={`mt-[2px] w-2 transition-all duration-300 ${location.pathname === '/home' ? '' : 'hidden group-hover:block'}`} />
            </Link>
            <Link to ='/friends' className={`flex flex-col items-center w-32 transition-all duration-300 group ${location.pathname === '/friends' ? 'scale-125' : 'hover:scale-125'}`}>
                <Image src={Icons.friends} />
                <span className='text-[10px]'>Friends</span>
                <img src={Icons.triangle} className={`mt-[2px] w-2 transition-all duration-300 ${location.pathname === '/friends' ? '' : 'hidden group-hover:block'}`} />
            </Link>
            <Link to ='/tasks' className={`flex flex-col items-center w-32 transition-all duration-300 group ${location.pathname === '/tasks' ? 'scale-125' : 'hover:scale-125'}`}>
                <Image src={Icons.tasks} />
                <span className='text-[10px]'>Tasks</span>
                <img src={Icons.triangle} className={`mt-[2px] w-2 transition-all duration-300 ${location.pathname === '/tasks' ? '' : 'hidden group-hover:block'}`} />
            </Link>
            <Link to='/leaderboard' className={`flex flex-col items-center w-32 transition-all duration-300 group ${location.pathname === '/leaderboard' ? 'scale-125' : 'hover:scale-125'}`}>
                <Image src={Icons.leaderboard} />
                <span className='text-[10px]'>Leaderboard</span>
                <img src={Icons.triangle} className={`mt-[2px] w-2 transition-all duration-300 ${location.pathname === '/leaderboard' ? '' : 'hidden group-hover:block'}`} />
            </Link>
        </div>
    );
}

export default Footer;