import { ReactSVG } from 'react-svg';
import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import Image from '../components/Image';
import { useTasksContext } from '../context/TasksContext';
import { formatNumberWithLetters, formatAmericanNumber } from '../utils/numberFormatter';
import { useSpring, animated } from '@react-spring/web';
import Icons from '../assets/icons.json';

const Tasks = function () {
    const { dailyRemainSecond, tasks, dailyTasks, claimStates, handleTelegram, handleTwitter, handleInviteFriends, handleTelegramPremium, onClaimDailyReward, setDailyRemainSecond, taskStatuses, refreshClaimStatus } = useTasksContext();

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Define the animation for the rocket
    const { x, y } = useSpring({
        from: { x: 0, y: 0 },
        to: async (next) => {
            while (true) {
                await next({ x: getRandomInt(-5,10), y: getRandomInt(-5,10), config: { tension: 100, friction: 30, duration: 1000 } });
                await next({ x: 0, y: 0, config: { tension: 100, friction: 20, duration: 1000 } });
            }
        },
        reset: true,
    });

    useEffect(() => {
        // Find the daily bonus task and refresh claim status
        const dailyTask = dailyTasks.find(task => task.task_type === 'daily-bonus');
        if (dailyTask) {
            refreshClaimStatus(dailyTask._id);
        }
    }, []);

    return (
        <div className='flex flex-col w-full pb-[100px] min-h-screen'>
            <div className='flex items-center justify-between mt-[20px] ml-[32px]'>
                <div>
                    <p className='text-[30px] font-medium-plus'>Your Tasks</p>
                    <p className='mt-1 text-[14px]'>Complete the tasks to get more tokens</p>
                </div>
                <div>
                    <animated.div
                        style={{
                            transform: x.to((xVal) => `translate(${xVal}px, ${y.get()}px)`),
                        }}
                    >
                        <Image className='w-[100px] h-[115px] mr-[20px]' src="./imgs/rocket.svg" alt='rocket' />
                    </animated.div>
                </div>
            </div>
            <div className='mt-[6px] mx-[32px]'>
                <div className='text-[24px] font-medium-plus'>Daily tasks</div>
                {dailyTasks !== undefined && dailyTasks.length === 0 && (
                    <div className='mt-4 px-4 items-center text-center rounded-lg bg-white bg-opacity-40 py-3 pb-5'>
                        <Image src='./imgs/dailydone.svg' className='w-[150px] h-[150px] mx-auto' alt="daily tasks done" />
                        <span className='text-[15px]'>Good job, buddy! Don't forget to come back tomorrow for more rewards!</span>
                    </div>
                ) }
                {dailyTasks !== undefined && dailyTasks.length !== 0 && dailyTasks.map((dailytask, index) => {
                    var status = taskStatuses[dailytask._id];
                    return (
                        <div className={`flex items-center justify-between py-3 ${index !== dailyTasks.length - 1 ? 'border-b' : ''}`}>
                            <div className='flex items-center gap-2'>
                                <div className='flex items-center justify-center w-[43px] h-[43px] bg-white border rounded-lg bg-opacity-10'>
                                    <Image src={Icons[dailytask.task_icon]} />
                                </div>
                                <div>
                                    <div className='text-[14px]'>{dailytask.name}</div>
                                    <div className='text-[14px] text-white text-opacity-[75%]'>+ {formatAmericanNumber(dailytask.token_amount)} POCKET</div>
                                </div>
                            </div>

                            { dailytask.task_type === 'daily-bonus' ? (
                                
                                 dailyRemainSecond <= 0 ? <button onClick={() => onClaimDailyReward(dailytask._id)} className='w-[85px] h-[31px] bg-white text-[14px] text-[#97c5d3] rounded-lg shadow-md transition duration-150 active:shadow-inner active:bg-gray-200'>{dailytask.task_button}</button>
                                : <button className='w-[85px] h-[31px] text-[14px] bg-[#c9e0e5] rounded-lg shadow-sm'>
                                    <Countdown date={Date.now() + dailyRemainSecond} intervalDelay={1000} precision={3} onComplete={() => setDailyRemainSecond(0)} renderer={(props) => <span>{String(props.hours).padStart(2, '0')} : {String(props.minutes).padStart(2, '0')} : {String(props.seconds).padStart(2, '0')}</span>}></Countdown>
                                </button> 

                            ) : (
                                <button disabled className='w-[85px] h-[31px] bg-[#93cff9] text-[14px] rounded-lg'>Disabled</button>
                            )}
                        </div>
                    );
                })}
            </div>
            <div className='mt-[25px] mx-[32px]'>
                <div className='text-[24px] font-medium-plus'>Tasks</div>
                {tasks !== undefined && tasks.length === 0 && (
                    <div className='mt-4 px-4 items-center text-center rounded-lg bg-white bg-opacity-40 py-3 pb-5'>
                        <Image src='./imgs/tasksdone.svg' className='w-[150px] h-[150px] mx-auto' alt="tasks done" />
                        <span className='text-[15px]'>Incredible, champ! You've run out of tasks, but don't worry — more are on the way!</span>
                    </div>
                ) }
                {tasks !== undefined && tasks.length !== 0 && tasks.map((task, index) => {
                    var status = taskStatuses[task._id];
                    const isClaimStage = claimStates[task._id];
                    return (
                        <div className={`flex items-center justify-between py-3 ${index !== tasks.length - 1 ? 'border-b' : ''}`}>
                            <div className='flex items-center gap-2'>
                                <div className='flex items-center justify-center w-[43px] h-[43px] bg-white border rounded-lg bg-opacity-10'>
                                    <Image src={Icons[task.task_icon]} />
                                </div>
                                <div>
                                    <div className='text-[14px]'>{task.name}</div>
                                    <div className='text-[14px] text-white text-opacity-[75%]'>+ {formatAmericanNumber(task.token_amount)} POCKET</div>
                                </div>
                            </div>
                            {task.task_type === 'tg-join' ? (
                                <button onClick={() => handleTelegram(task.task_metadata?.task_url, task._id)} className={`w-[85px] h-[31px] ${status ? 'bg-white text-[#97c5d3] shadow-md transition duration-150 active:shadow-inner active:bg-gray-200' : 'bg-[#c9e0e5] shadow-sm'} text-[14px] rounded-lg ${isClaimStage ? 'font-medium-plus' : ''}`}>{status ? (isClaimStage ? 'Claim' : task.task_button) : 'Claimed'}</button>
                            ) : task.task_type === 'tw-follow' ? (
                                <button onClick={() => handleTwitter(task.task_metadata?.task_url, task._id)} className={`w-[85px] h-[31px] ${status ? 'bg-white text-[#97c5d3] shadow-md transition duration-150 active:shadow-inner active:bg-gray-200' : 'bg-[#c9e0e5] shadow-sm'} text-[14px] rounded-lg ${isClaimStage ? 'font-medium-plus' : ''}`}>{status ? (isClaimStage ? 'Claim' : task.task_button) : 'Claimed'}</button>
                            ) : task.task_type === 'invite-friends' ? (
                                <button onClick={() => handleInviteFriends(task._id)} className={`w-[85px] h-[31px] ${status ? 'bg-white text-[#97c5d3] shadow-md transition duration-150 active:shadow-inner active:bg-gray-200' : 'bg-[#c9e0e5] shadow-sm'} text-[14px] rounded-lg ${isClaimStage ? 'font-medium-plus' : ''}`}>{status ? (isClaimStage ? 'Claim' : task.task_button) : 'Claimed'}</button>
                            ) : task.task_type === 'tg-premium' ? (
                                <button onClick={() => handleTelegramPremium(task._id)} className={`w-[85px] h-[31px] ${status ? 'bg-white text-[#97c5d3] shadow-md transition duration-150 active:shadow-inner active:bg-gray-200' : 'bg-[#c9e0e5] shadow-sm'} text-[14px] rounded-lg ${isClaimStage ? 'font-medium-plus' : ''}`}>{status ? (isClaimStage ? 'Claim' : task.task_button) : 'Claimed'}</button>
                            ) : (
                                <button disabled className='w-[85px] h-[31px] bg-[#c9e0e5] text-[14px] rounded-lg shadow-sm'>Disabled</button>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default Tasks;